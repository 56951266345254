import React from 'react';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';
import LeadMagnetLPlayout from '../organisms/LeadMagnetLPlayout';

const WorkshopLp = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <LeadMagnetLPlayout translations={translations} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default WorkshopLp;
